<template>
  <div class="home page">
    <h2 class="accent--text">
      <v-icon color="primary" class="ml-3" large>mdi-monitor-dashboard</v-icon>
      ادارة الترندات
      <back-button />
    </h2>
    <hr class="my-5 header-divider" />

    <!-- CREATE / UPDATE FROM -->
    <record-form />

    <!-- UPDATE / NEW RECORD -->
    <filters-box @fetch="fetchRecords({ page: 1 })" />
    <div class="mb-3 mt-9 text-left">
      <v-btn
        large
        color="primary"
        elevation="0"
        @click="
          SET_FORM_RECORD({});
          SET_EDIT_MODE(false);
          SHOW_FORM();
        "
      >
        اضافة ترند جديد
        <v-icon class="mr-2">mdi-plus</v-icon>
      </v-btn>
    </div>
    <v-chip right class="mb-2" label color="secondary">
      <v-icon> mdi-account-circle-outline </v-icon> عدد الترندات
      {{ total_trends }}</v-chip
    >
    <!-- TABLE -->
    <v-data-table
      item-key="id"
      class="elevation-1"
      :loading="table.loading"
      :headers="table.headers"
      :items="records"
      loading-text="Downloading Data"
      no-data-text="No Data"
      disable-filtering
      disable-pagination
      disable-sort
      hide-default-footer
    >
      <template v-slot:item.file="{ item }">
        <img
          v-if="item.file_type == 'image'"
          :src="item.file"
          width="300px"
          class="mt-3 mb-2 rounded-lg"
        />
        <video
          v-else="item.file_type == 'video'"
          width="300px"
          class="mt-3 mb-2 rounded-lg"
          controls
          preload="none"
        >
          <source :src="item.file" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </template>
      <!-- CONTROLS -->
      <template v-slot:item.controls="{ item }">
        <v-btn
          small
          icon
          color="secondary"
          class="mx-1"
          @click="
            SET_FORM_RECORD(item);
            SET_EDIT_MODE(true);
            SHOW_FORM();
          "
        >
          <v-icon>mdi-circle-edit-outline</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.blocked="{ item }">
        <v-switch
          v-if="item.active == 0"
          inset
          :input-value="false"
          @change="unblock(item)"
        ></v-switch>
        <v-switch
          v-else="item.active == 1"
          color="success"
          inset
          :input-value="true"
          @change="block(item)"
        ></v-switch>
      </template>
    </v-data-table>

    <!-- PAGINATION -->
    <div class="text-center mt-7">
      <v-pagination
        total-visible="7"
        @input="$router.replace({ query: { ...$route.query, page: $event } })"
        :value="parseInt(filters.page)"
        :length="totalPages"
        circle
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import RecordForm from "./RecordForm";
import { mapState, mapMutations } from "vuex";
import FiltersBox from "./FiltersBox.vue";
export default {
  name: "trends",
  components: { RecordForm, FiltersBox },

  data: function () {
    return {
      // table
      table: {
        headers: [
          {
            value: "country",
            text: "الدولة",
          },
          {
            value: "file",
            text: "الصورة",
          },
          {
            value: "watched",
            text: "المشاهدة",
          },
          {
            value: "blocked",
            text: "تفعيل/تعطيل",
          },
          {
            value: "controls",
            text: "تعديل",
          },
        ],
        loading: false,
      },
    };
  },

  computed: {
    ...mapState("trends", [
      "records",
      "page",
      "totalPages",
      "filters",
      "total_trends",
    ]),
  },
  watch: {
    $route: {
      async handler(route) {
        if (!route.query.page) {
          await this.$router.replace({ query: { ...route.query, page: 1 } });
          return;
        }

        this.SET_FILTERS({ ...route.query });
        this.fetchRecords(route.query);
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapMutations("trends", [
      "SHOW_FORM",
      "SET_EDIT_MODE",
      "SET_FORM_RECORD",

      "SHOW_RECORD_FORM",

      "SET_FILTERS",
    ]),

    fetchRecords() {
      //       fetchRecords() {
      //   this.table.loading = true;

      //   this.$store
      //     .dispatch("categories/fetchRecords")
      //     .catch(error => {
      //       this.$bus.$emit("showSnackbar", {
      //         text: "خطأ أثناء جلب البيانات",
      //         color: "red darken-3"
      //       });
      //     })
      //     .finally(() => {
      //       this.table.loading = false;
      //     });
      // },
      this.table.loading = true;
      // // console.log(page);
      this.$store
        .dispatch("trends/fetchRecords")
        .catch((error) => {
          this.$bus.$emit("showSnackbar", {
            text: "Error while getting Data",
            color: "accent",
          });
        })
        .finally(() => {
          this.table.loading = false;
        });
    },

    // async remove(selectedRecord) {
    //   const confirmMessageParams = [
    //     `حذف المشرف رقم ${selectedRecord.id}!`,
    //     `هل أنت متأكد من حذف المشرف '${selectedRecord.name}'؟`,
    //     { color: "error" }
    //   ];
    //   if (!(await this.$root.$confirm(...confirmMessageParams))) return;

    //   this.$store
    //     .dispatch("admins/delete", { recordId: selectedRecord.id })
    //     .then(() => {
    //       this.$bus.$emit("showSnackbar", {
    //         text: "تم حذف المشرف بنجاح",
    //         color: "success"
    //       });
    //     })
    //     .catch(error => {
    //       this.$bus.$emit("showSnackbar", {
    //         text: "خطأ أثناء حذف المشرف",
    //         color: "accent"
    //       });
    //     });
    // },
    async unblock(selectedRecord) {
      this.table.loading = true;

      await this.$store
        .dispatch("trends/unblock", { record: { ...selectedRecord } })
        .then(() => {
          this.$bus.$emit("showSnackbar", {
            text: `تم فك حظر التريند`,
            color: "success",
          });
          this.fetchRecords();
        })
        .catch((error) => {
          this.$bus.$emit("showSnackbar", {
            text: "Error while unblocking Admin  ",
            color: "red darken-3",
          });
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    async block(selectedRecord) {
      this.table.loading = true;

      await this.$store
        .dispatch("trends/block", { record: { ...selectedRecord } })
        .then(() => {
          this.$bus.$emit("showSnackbar", {
            text: `تم حظر التريند`,
            color: "warning",
          });
          this.fetchRecords();
        })
        .catch((error) => {
          this.$bus.$emit("showSnackbar", {
            text: " Error while blocking Admin",
            color: "red darken-3",
          });
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
  },
};
</script>
