<template>
  <v-dialog
    :value="showForm"
    @input="(v) => v || HIDE_FORM()"
    max-width="600px"
    scrollable
  >
    <v-card>
      <!-- TITLE -->
      <v-card-title class="primary">
        <span class="headline">{{
          editMode ? " تعديل ترند" : "اضافة ترند جديد"
        }}</span>
      </v-card-title>

      <!-- INPUTS -->
      <v-card-text class="pt-5 pb-0">
        <v-container>
          <v-progress-linear
            v-if="formLoading"
            color="primary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
          <div v-else>
            <v-autocomplete
              dense
              outlined
              hide-details
              clearable
              label="البلد"
              prepend-icon="mdi-sitemap"
              class="mb-5"
              item-text="country"
              item-value="id"
              v-model="form.country_id"
              :error-messages="errors.country_id"
              :items="countries"
            ></v-autocomplete>

            <v-autocomplete
              dense
              outlined
              hide-details
              clearable
              label="نوع الملف"
              prepend-icon="mdi-sitemap"
              class="mb-5"
              item-text="name"
              item-value="name"
              v-model="form.file_type"
              :error-messages="errors.file_type"
              :items="files"
            ></v-autocomplete>

            <v-file-input
              v-if="form.file_type == 'image'"
              dense
              outlined
              label="الصورة"
              prepend-icon="mdi-image"
              accept="image/*"
              v-model="form.file"
              :error-messages="errors.file"
            ></v-file-input>
            <v-file-input
              v-else
              dense
              outlined
              label="الفيديو"
              prepend-icon="mdi-video"
              accept="video/*"
              v-model="form.file"
              :error-messages="errors.file"
            ></v-file-input>
            <p v-if="form.file_type == 'image'" class="error_class">
              *accept JPJ SIZES 1200*600 AND LESS
            </p>
            <p v-else class="error_class">*accept MP4</p>
              
                <v-text-field
                  v-model="form.whatsapp"
                  label="واتساب"
                  outlined
                ></v-text-field>
              
              
                <v-text-field
                  v-model="form.phone"
                  label="الهاتف"
                  outlined
                ></v-text-field>
              
               <p>* ادخل رقم الهاتف مسبوق بكود الدولة</p>

                <v-text-field
                  v-model="form.title"
                  label="عنوان الإعلان"
                  outlined
                ></v-text-field>
              
          </div>
        </v-container>
      </v-card-text>

      <!-- ACTIONS -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :loading="formLoading"
          color="secondary"
          elevation="1"
          dark
          @click="editMode ? update(form) : create(form)"
          >حفظ</v-btn
        >
         <v-btn @click="HIDE_FORM();"> 
          إلغاء
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapState, mapMutations , mapActions} from "vuex";

export default {
  name: "record-form",

  data: function () {
    return {
      form: {},
      errors: {},
      formLoading: false,
      sections: [],
      countries: [],
      files: [{ name: "image" }, { name: "video" }],
    };
  },
  created() {
      this.fetchCountry();
      this.fetchSections();
  },
  computed: {
    ...mapState("trends", {
      editMode: (state) => state.editMode,
      showForm: (state) => state.showForm,
      formRecord: (state) => {
        return {
          id: state.formRecord.id,
          country_id: state.formRecord.country_id,
          file_type: state.formRecord.file_type,
          title : state.formRecord.title,
          whatsapp : state.formRecord.whatsapp,
          phone : state.formRecord.phone,
          // file: state.formRecord.file,
          // icon: state.formRecord.icon
        };
      },
    }),
  },

  watch: {
    formRecord(form) {
      this.form = form;
    },
  },

  methods: {
    ...mapMutations("trends", ["HIDE_FORM"]),
    ...mapActions("trends", ["fetchRecords"]),
    fetchSections() {
      this.axios.get("/admin/sliders/section_dropdown").then((response) => {
        this.sections = response.data;
      });
    },

    fetchCountry() {
      this.axios.get("/admin/sliders/countries_dropdown").then((response) => {
        this.countries = response.data;
        // console.log(this.files);
      });
    },
    create(form) {
      this.formLoading = true;
      // form.section_id = this.item.id;
      // // console.log(form.section_id);
      // console.log(form);
      this.$store
        .dispatch("trends/create", { form })
        .then((response) => {
          this.$bus.$emit("showSnackbar", {
            text: "تمت اضافة التصنيف بنجاح",
            color: "success",
          });
          this.HIDE_FORM();
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
          this.$bus.$emit("showSnackbar", {
            text: "خطا اثناء اضافة التصنيف",
            color: "error",
          });
          this.HIDE_FORM();
        })
        .finally(() => {
          this.formLoading = false;
          this.fetchRecords();
        });
    },

    update(form) {
      this.formLoading = true;
      // console.log({ form });
      this.$store
        .dispatch("trends/update", { form })
        .then(() => {
          this.$bus.$emit("showSnackbar", {
            text: "Data updated",
            color: "success",
          });
          this.HIDE_FORM();
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
          this.$bus.$emit("showSnackbar", {
            text: "There was a mistake when updating the category.",
            color: "error",
          });
        })
        .finally(() => {
          this.formLoading = false;
          this.fetchRecords();

        });
    },
  },
};
</script>
