<template>
  <div class="filter-box py-5 px-4 mb-6">
    <h3 class="primary--text mb-3"><v-icon class="ml-1">mdi-filter</v-icon>التصنيفات </h3>
    <v-row align="center">
      <!-- QUERY -->
      
      <!-- Q -->
      <v-col class="d-flex" cols="12" sm="6">
        <v-autocomplete
              rounded
              outlined
              hide-details
              clearable
              solo
              label="الدولة"
              class="mb-5"
              item-text="country"
              item-value="id"
              v-model="filters.country_id"
              :items="countries"
            ></v-autocomplete>
      </v-col>
              <v-text-field
          label="العنوان"
          hide-details="auto"
          class="mb-5"
          v-model="filters.title"
          rounded
          outlined
        />
        <v-col class="d-flex" cols="12" sm="6">
        <v-menu
          ref="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              rounded
              outlined
              hide-details
              clearable
              solo
              v-model="filters.created_at"
              label="اختر تاريخ الانشاء"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filters.created_at"
            :max="
              new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10)
            "
            min="1950-01-01"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <!-- SEARCH BUTTON -->
      <v-col class="d-flex" cols="6">
        <v-spacer></v-spacer>
        <v-btn class="mr-2"
          @click="$router.replace({ query: { ...filters, page: 1 } })"
        large
        color="primary">بحث</v-btn>
          
        <v-btn
          class="mr-2"
          @click="$router.replace({ query: { page: 1 , active:0} })"
          large
          color="primary"
          >عرض الكل
        </v-btn>
        <v-btn
          class="mr-2"
          @click="$router.replace({ query: { page: 1 , active:1} })"
          large
          color="primary"
        >
          عرض النشط
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import { mapState, mapMutations ,mapActions} from 'vuex';

export default {
  name: 'filters-box',


  data: function () {
    return {
      categoriesLoading: false,

      filters: {
        filter: null,
      
      },
      countries: [],
    }
  },


  created() {
      this.fetchCountry();
  },


  computed: {
  },


  methods: {
    ...mapMutations('trends', ['SET_FILTERS']),
    ...mapActions('trends', ['fetchRecords']),
    fetchCountry()
    {
      this.axios.get("/admin/sliders/countries_dropdown").then((response) =>{
        this.countries = response.data;
      });
    }
  }
}
</script>
